<template>
  <div class="measure-list">
    <overlay
      v-if="routematch || customMeasure"
      :position="position"
      :class="customOverlayClass"
      :width="width"
      :prompt-before-close="promptBeforeClose"
      :hide-close-button="hideCloseButton"
      @close="closeOverlay"
    >
      <template slot="content">
        <div v-if="customMeasure">
          <clinician-customize-measure
            :measure-id="customMeasure"
            @finish="customizationFinished"
          />
        </div>
        <router-view v-else />
      </template>
    </overlay>
    <ClinicianEditMeasureResponses
      v-if="measureToEditData"
      :measure="measureToEditData.measure"
      :patient="measureToEditData.patient"
      @finish="finish"
    />
    <div v-if="!loading">
      <div
        v-if="(!measures || !measures.length)"
        class="no-measures-box"
      >
        <span> {{ $t( showAddMeasure ? 'noMeasuresAssigned' : 'noMeasuresAssignedWithoutLink') }}  </span>
        <router-link
          v-if="showAddMeasure"
          :to="{name: 'ClinicianPatientAddMeasure', params: { ccauId: this.$route.params.ccauId, ccaId: this.$route.params.ccaId }}"
          @click.native="plusOpen=false"
        >
          <a href="#">{{ $t('addAMeasure') }}</a>
        </router-link>
      </div>
      <Measure-list-item
        v-for="(measure, index) in measures"
        :key="measure.id"
        :data="measure"
        :chart-index-init="getChartIndex(measure)"
        :missing-answers="measure.sessions_completed.length ? measure.missing_answers : null"
        :index="index"
        :audition="false"
        @openEditMeasure="openEditMeasure"
        @viewDetail="viewDetail"
      />
    </div>
    <div v-else>
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import ClinicianCustomizeMeasure from '@/components/clinician/measures/ClinicianCustomizeMeasure'
import ClinicianEditMeasureResponses from '@/components/clinician/measures/ClinicianEditMeasureResponses'
import MeasureListItem from './MeasureListItem'
import { PatientHelper } from '@/mixins/PatientHelper'
import { mapGetters, mapMutations } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import Overlay from '@/components/clinician/Overlay'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'ClinicianPatientMeasures',
  components: {
    ClinicianCustomizeMeasure,
    MeasureListItem,
    ClinicianEditMeasureResponses,
    LoadingSpinner,
    Overlay
  },
  mixins: [Helpers, PatientHelper],
  props: {},
  data () {
    return {
      loading: false,
      measureToEditData: null
    }
  },
  computed: {
    ...mapGetters({
      measures: 'getPatientMeasures',
      patientData: 'getSinglePatient',
      customMeasure: 'getCustomMeasure',
      schedulingOptions: 'getSchedulingOptions',
      activeAssignment: 'getActiveAssignment',
      formDirty: 'getActiveFormDirty'
    }),
    width () {
      return this.customMeasure ? window.innerWidth > 800 ? 800 : window.innerWidth : null
    },
    routematch () {
      return ['ClinicianPatientAddMeasure', 'ClinicianPatientMeasureDetail', 'ClinicianPatientMeasuresAddedOverview'].includes(this.$route.name)
    },
    customOverlayClass () {
      return this.$route.name === 'ClinicianPatientMeasureDetail' ? 'measure-list-overlay' : ''
    },
    position () {
      return this.$route.name === 'ClinicianPatientMeasureDetail' ? 'right' : 'left'
    },
    hideCloseButton () {
      return this.$route.name === 'ClinicianPatientMeasuresAddedOverview'
    }
  },
  watch: {
    '$route.params' () {
      this.getMeasures()
      this.checkIfClinicalAdminesteredMeasure()
    }
  },
  created () {
    componentRefreshSingleton.addComponentToRefresh(this.$options.name, this)
    this.getMeasures()
  },
  destroyed () {
    componentRefreshSingleton.removeComponentToRefresh(this.$options.name)
    this.resetPatientMeasures([])
  },
  methods: {
    ...mapMutations({
      resetPatientMeasures: 'patients/GET_PATIENTS_MEASURES',
      resetMeasuresAssigned: 'measures/RESET_MEASURES_ASSIGNED_DURING_SESSION'
    }),
    checkIfClinicalAdminesteredMeasure () {
      const clinicalAdministeredMeasureId = this.getParamFromQueryAndRemoveIt('clinicalAdministeredMeasure')
      if (clinicalAdministeredMeasureId) {
        this.openClinicalAdministeredMeasure(clinicalAdministeredMeasureId)
      }
    },
    openClinicalAdministeredMeasure (id) {
      const patient = {
        name: this.activeAssignment.full_name,
        patient_relation: this.activeAssignment.patient_relation,
        patient_mrn: this.activeAssignment.patient_mrn,
        patient_name: this.activeAssignment.patient_name,
        patient_relation_id: this.activeAssignment.patient_relation_id
      }
      const measureData = this.measures.find(m => m.next_schedules.find(ne => Number(ne.id) === id)) || this.schedulingOptions.measures_assigned.find(ma => Number(ma.id) === id)
      const isPrePopulate = measureData ? measureData.is_pre_populate : false

      const measure = { client_measure_id: id, clinicalAdministered: true, is_pre_populate: isPrePopulate }
      const params = { patient, measure }
      this.measureToEditData = params
    },
    viewDetail (d) {
      this.$router.push({ name: 'ClinicianPatientMeasureDetail', params: { ccauId: this.$route.params.ccauId, ccaId: this.$route.params.ccaId, libraryId: this.$getEncodedId(d.library_id) } })
    },
    refreshView () {
      return this.getMeasures()
    },
    getMeasures () {
      const ccauId = this.$getDecodedId(this.$route.params.ccauId)
      this.loading = true
      this.$store.dispatch('GET_PATIENTS_MEASURES', ccauId).finally(() => {
        this.loading = false
      })
      this.$store.dispatch('GET_SCHEDULING_OPTIONS', { ccauId: this.$getDecodedId(this.$route.params.ccauId) })
    },

    customizationFinished () {
      this.$toast.success({ message: this.$t('customMeasureUpdated') })
      this.$store.dispatch('SET_CUSTOM_MEASURE', null)
      this.closeOverlay()
    },
    closeOverlay () {
      this.measureToEditData = null
      this.resetMeasuresAssigned()
      if (this.customMeasure) {
        this.$store.dispatch('SET_CUSTOM_MEASURE', null)
      }
      this.$router.push({ name: 'ClinicianPatientMeasures' })
    },
    promptBeforeClose () {
      const routeName = this.$route.name
      return new Promise((resolve) => {
        if (['ClinicianPatientAddMeasure'].includes(routeName) && this.formDirty) {
          const promptOptions = {
            message: this.$t('overlayCancelAdd', { data: 'measure' }),
            title: this.$t('areYouSureToExit'),
            okButton: this.$t('yesExit')
          }
          this.$promptBeforeAction(promptOptions, () => {
            resolve()
          })
        } else {
          resolve()
        }
      })
    },
    openEditMeasure (measure) {
      const patient = {
        name: this.activeAssignment.full_name,
        patient_relation: this.activeAssignment.patient_relation,
        patient_mrn: this.activeAssignment.patient_mrn,
        patient_name: this.activeAssignment.patient_name,
        patient_relation_id: this.activeAssignment.patient_relation_id
      }
      const params = { patient: patient, measure: measure }
      params.measure.client_measure_id = params.measure.sessions_completed[0].id
      this.measureToEditData = { patient: patient, measure: measure }
    },
    getChartIndex (measure) {
      if (this.$route.query.chartIndex && this.$route.query.measureId && measure.id === this.$route.query.measureId) {
        return this.$route.query.chartIndex
      }
      return 0
    },
    finish () {
      this.measureToEditData = null
      this.getRespondentLatestSession()
      this.getMeasures()
      if (this.formDirty) {
        this.$toast.success({ message: this.$t('measureResponsesUpdated') })
      }
    }
  }
}
</script>
